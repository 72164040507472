






























import { DocumentField } from "@/use/document";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { useVModel } from "@vueuse/core";

export default defineComponent({
  name: "MProtocolEditorField",
  components: {
    MDatePicker: () => import("@/components/molecules/m-date-picker.vue"),
    MTimePicker: () => import("@/components/molecules/m-time-picker.vue"),
  },
  props: {
    field: {
      type: Object as PropType<DocumentField>,
      required: true,
    },
    value: {
      type: null,
      required: false,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const model = useVModel(props, "value", emit, { eventName: "input" });

    const elements = computed(() =>
      Array.isArray(model.value) ? model.value : []
    );

    const addElement = () => {
      if (props.field.type === "repeater") {
        const element = Object.fromEntries(
          props.field.children?.map((item) => [item.name, null]) ?? []
        );
        if (Array.isArray(model.value)) model.value.push(element);
        else model.value = [element];
      }
    };

    const removeElement = (index: number) => {
      if (props.field.type === "repeater") {
        if (Array.isArray(model.value)) model.value.splice(index, 1);
        else model.value = [];
      }
    };

    return { model, elements, addElement, removeElement };
  },
});
